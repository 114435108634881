import { Component, Vue } from 'vue-property-decorator';
import print from 'print-js';
import receiptService from '@/api/receipt-management/receipt';
@Component({
  name: 'print-preview'
})
export default class PrintPreview extends Vue {
  public imageUrlList: Array<any> = [];
  public idList: Array<number> = [];
  /**
   * 全部打印
   */
  public allPrint(): void {
    print('print', 'html');
    receiptService.printLayout(this.idList);
  }

  public created(): void {
    this.imageUrlList = this.$route.query.imageUrlList as Array<any>;
    this.idList = this.$route.query.idList as Array<any>;
  }
}
